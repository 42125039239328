import React, { useState } from 'react';
// core components
import DemoFooter from "components/Footers/DemoFooter.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ImageLaurinda from '../assets/img/faces/laurinda2.jpg';
import ImageRelaxamento from '../assets/img/services/693.svg';
import ImageBarbearia from '../assets/img/services/696.svg';
import ImageEstetica from '../assets/img/services/702.svg';
import ImageCabeleireiro from '../assets/img/services/712.svg';
import Page from '../components/Page';

// reactstrap components
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import GoogleMapsWrapper from 'components/maps/GoogleMapsWrapper';
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import { postEmail } from '../utils/ApiRequest';

const servicoCabeleireiro = ["Brushing", "Corte de Cabelo", "Penteados Artístico", "Ondulação", "Desfrizagem", "Alisamento", "Coloração", "Madeixas", "Limpeza de Tom",
    "Tratamentos Capilares", "Botox Capilar", "Peeling Esfoliante", "Mascára de Argila", "Tratamento de Reconstrução", "Tratamento de Creatina",
    "Tratamento de Radicais", "Tratamento Bio-Care", "Tratamento Bio-repair", "Talassoterapia Capilar", "Tratamento de Proteção de Cor"]

const servicoBarbearia = ["Corte Masculino", "Barba", "Massagem Facial", "Manicure homem", "Pedicure Homem"];

const servicoEstetica = ["Manicure", "Pedicure", "Aplicação de vernis", "Vernis Gel", "Unhas de Gel", "Art Nail", "Maquilhagem de Dia", "Maquilhagem de Noite", "Maquilhagem",
    "Depilação a cera", "Depilação a Linha", "Depilação a Laser (Fotodepilação)", "Limpeza de Pele", "Lifting de Pestanas", "Extenção de Pestanas", "Tratamento Anti-manchas", "Tratamento Contorno de Olhos e Papos",
    "Tratamento Anti-rugas", "Tratamento Nutritivo de Cacau", "Tratamento Rosa da Bulgária", "Tratamento Citricos Anti-idade", "Tratamento Purificante Anti-acne",
    "Spa Thalasso Energy Lifting Feminino e Masculino", "Tratamento Spa de Chá Verde", "Spa Thalasso Firmeza Corporal", "Spa Thalasso Firmeza de Seios",
    "Spa Detox de Iodo Marinho", "Spa Anti Celulitico Com Algoterapia", "Spa Vinoterapia", "Spa Circulatorio Com Vinoterapia", "Spa hidratação Com Côco e Cenoura", "Spa Rosa da Bulgaria Regenerador",
    "Spa Vinoterapia", "Spa Descontraturante Nuca e Costas", "Spa de mãos", "Spa pedicure", "Pressoterapia", "Cavitação", "Radio Frequência", "Lifting"];

const servicoMassagens = [
    "Massagem Gemoterapia", "Massagem Relaxante", "Massagem Reiki", "Massagem para Casais", "Massagem de Velas", "Massagem Desportiva", "Massagem de Bambu", "Drenagem Linfática Manual",
    "Massagem Com Pindas"
]

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const LandigPage = () => {
    const [email, setEmail] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [snackBar, setSnackBar] = useState({
        open: false,
        message: '',
        severity: 'success'
    })
    const [services, setServices] = useState({
        open: false,
        list: [],
        title: ""
    });

    const toggle = () => setServices((prevState) => {
        return {
            ...prevState,
            open: false
        }
    });

    //Google Maps
    const onMountMap = map => {

        const markerCoord = {
            title: 'marker',
            coords: {
                lat: 40.213688,
                lng: -8.426626,
            },
        }

        const marker = new window.google.maps.Marker({
            map,
            position: markerCoord.coords,
            draggable: false,
        });


        map.setCenter(marker.position);
    }

    const mapOptions = getMapOptions();

    const handleCloseSnackBar = () => {
        const snack = { ...snackBar }
        snack.open = false;
        setSnackBar(snack);
    }

    function validateMessage() {

        if (email.name === undefined || email.name === null || email.name.length < 1) {
            return false;
        }

        if (email.email === undefined || email.email === null || email.email.length < 1) {
            return false;
        }

        if (email.message === undefined || email.message === null || email.message.length < 1) {
            return false;
        }

        return true;
    }

    function validateEmail() {
        if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email.email)) {
            return true;
        }

        const snack = { ...snackBar }
        snack.open = true;
        snack.severity = 'error';
        snack.message = 'O endereço de email não é valido.'
        setSnackBar(snack);

        return false;
    }

    function getMapOptions() {
        return {
            center: { lat: 40.213688, lng: -8.426626, },
            zoom: 16,
        }
    }

    return (
        <Page title="Salão Zenite">
            <ExamplesNavbar />
            <LandingPageHeader />
            <div className="main">
                <div className="section text-center" id="services_container">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="8">
                                <h2 className="title">Os nossos serviços</h2>
                                <h5 className="description">
                                    Somos um salão minuciosamente pensado e concebido para oferecer todos os tipos de serviços de beleza e estética, feminina e masculina.
                                </h5>
                                <br />
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <Row>
                            <Col md="3">
                                <div className="info">
                                    <div className="icon icon-info">
                                        <img alt="teste" src={ImageCabeleireiro} />
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Cabeleireiro</h4>
                                        <p className="description">
                                            Tratamos do seu visual, com o maior rigor e profissionalismo. Possuimos uma enorme lista de serviços tanto para o sexo feminino como masculino. Venha experimentar.
                                        </p>
                                        <Button className="btn-link" color="info" onClick={() => {
                                            setServices({
                                                list: servicoCabeleireiro,
                                                open: true,
                                                title: "Cabeleireiro"
                                            })
                                        }
                                        }>
                                            Ver Mais
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="info">
                                    <div className="icon icon-info">
                                        <img alt="teste" src={ImageBarbearia} />
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Barbearia</h4>
                                        <p>
                                            O Salão Zenite abriu uma área exclusiva para serviços de barbearia onde trabalhamos com uma gama de produtos exclusivamente dedicada a Homens.
                                        </p>
                                        <Button className="btn-link" color="info" onClick={() => {
                                            setServices({
                                                list: servicoBarbearia,
                                                open: true,
                                                title: "Barbearia"
                                            })
                                        }
                                        }>
                                            Ver Mais
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="info">
                                    <div className="icon icon-info">
                                        <img alt="teste" src={ImageEstetica} />
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Estética</h4>
                                        <p>
                                            Além dos serviços de estética tradicionais o nosso salão possui uma longa lista de tratamentos inovadores para diversas finalidades. Venha nos visitar estamos prontos para cuidar de si.
                                        </p>
                                        <Button className="btn-link" color="info" onClick={() => {
                                            setServices({
                                                list: servicoEstetica,
                                                open: true,
                                                title: "Estética"
                                            })
                                        }
                                        }>
                                            Ver Mais
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="info">
                                    <div className="icon icon-info">
                                        <img alt="teste" src={ImageRelaxamento} />
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Relaxamento</h4>
                                        <p>
                                            Porque a saúde mental e o bem estár são importantes para uma vida feliz, dispomos para si de vários serviços de relaxamento. Clique no botão "Ver Mais" para visualizar a lista.
                                        </p>
                                        <Button className="btn-link" color="info" onClick={() => {
                                            setServices({
                                                list: servicoMassagens,
                                                open: true,
                                                title: "Relaxamento"
                                            })
                                        }
                                        }>
                                            Ver Mais
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="about_us_container" className="section section-dark text-center">
                    <Container>
                        <h2 className="title">Sobre nós</h2>
                        <Row>
                            <Col md="12">
                                <Card className="card-profile card-plain">
                                    <div className="card-avatar">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                src={ImageLaurinda}
                                            />
                                        </a>
                                    </div>
                                    <CardBody>
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <div className="author">
                                                <CardTitle tag="h4">Laurinda Magalhães</CardTitle>
                                                <h6 className="card-category">Founder & Hair Stylist</h6>
                                            </div>
                                        </a>
                                        <p className="card-description text-center">
                                            Laurinda Magalhães nasceu em 1968, e ainda muito nova mudou-se para França com a sua família, onde tirou a sua formação de cabeleireiro na L'oreal Academy.
                                            Decidiu regressar a Portugal e à sua imagem construiu o Salão Zenite, onde o bem-estar e a satisfação dos clientes são uma prioridade.
                                            Ao longos dos anos apostou sempre na formação passando por cidades europeias como Barcelona e Madrid onde aprendeu o corte Vanguardia e também Londres onde tirou a formação
                                            Advanced Cutting Course na Eclipse Hairdressing Academy.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="section landing-section">
                    <Container fluid>
                        <Row>
                            <Col className="ml-auto mr-auto" md="3">
                                <div className="contact-form">
                                    <div className="typography-line">
                                        <h6>
                                            Morada:
                                        </h6>
                                    </div>
                                    <div className="typography-line">
                                        <p>
                                            Rua Dr. António José de Almeida Nº 28B,<br /> 3000-040 Coimbra
                                        </p>
                                    </div>
                                    <div className="typography-line">
                                        <h6>
                                            Telefone:
                                        </h6>
                                    </div>
                                    <div className="typography-line">
                                        <p>
                                            (+351) 239 403 077
                                        </p>
                                    </div>
                                    <div className="typography-line">
                                        <h6>
                                            Email:
                                        </h6>
                                    </div>
                                    <div className="typography-line">
                                        <p>
                                            salaozenite@hotmail.com
                                        </p>
                                    </div>
                                    <div className="typography-line">
                                        <h6>
                                            Horário:
                                        </h6>
                                    </div>
                                    <div className="typography-line">
                                        <p>
                                            De Segunda a Sábado, das 9h00m às 19h00m
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="4">
                                <h2 className="text-center">Entrar em contacto?</h2>
                                <Form className="contact-form">
                                    <Row>
                                        <Col md="12">
                                            <label>Nome</label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="nc-icon nc-single-02" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="Nome" type="text" value={email.name} onChange={event => {
                                                    const aux = { ...email }
                                                    aux.name = event.target.value;
                                                    setEmail(aux);
                                                }} />
                                            </InputGroup>
                                        </Col>
                                        <Col md="12">
                                            <label>Email</label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="nc-icon nc-email-85" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="Email" type="text" value={email.email} onChange={event => {
                                                    const aux = { ...email }
                                                    aux.email = event.target.value;
                                                    setEmail(aux);
                                                }} />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <label>Mensagem</label>
                                    <Input
                                        placeholder="Escreva-nos a sua messagem, prometemos ser breves na resposta..."
                                        type="textarea"
                                        rows="4"
                                        value={email.message}
                                        onChange={event => {
                                            const aux = { ...email }
                                            aux.message = event.target.value;
                                            setEmail(aux);
                                        }}
                                    />
                                    <Row>
                                        <Col className="ml-auto mr-auto" md="4">
                                            <Button className="btn-fill" color="danger" size="lg" onClick={
                                                () => {
                                                    if (!validateEmail()) {
                                                        return;
                                                    }
                                                    if (!validateMessage()) {
                                                        const snack = { ...snackBar }
                                                        snack.open = true;
                                                        snack.severity = 'error';
                                                        snack.message = 'Mensagem não é válida.'
                                                        setSnackBar(snack);
                                                        return;
                                                    }
                                                    const promise = postEmail(email);
                                                    promise.then(response => {
                                                        if (response !== undefined) {
                                                            const snack = { ...snackBar }
                                                            snack.open = true;
                                                            snack.severity = 'success';
                                                            snack.message = 'Mensagem enviada.'
                                                            setSnackBar(snack);
                                                            setEmail({
                                                                email: "",
                                                                name: "",
                                                                message: ""
                                                            })
                                                        } else {
                                                            const snack = { ...snackBar }
                                                            snack.open = true;
                                                            snack.severity = 'error';
                                                            snack.message = 'Mensagem não enviada.'
                                                            setSnackBar(snack);
                                                        }
                                                    }).catch(error => {
                                                        const snack = { ...snackBar }
                                                        snack.open = true;
                                                        snack.severity = 'error';
                                                        snack.message = 'Mensagem não enviada.'
                                                        setSnackBar(snack);
                                                    })
                                                }
                                            }>
                                                Enviar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col className="ml-auto mr-auto" md="5">
                                <GoogleMapsWrapper onMount={onMountMap} options={mapOptions} style={{ height: '500px', margin: '1em 0', borderRadius: '0.5em' }} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <DemoFooter />
            <Modal isOpen={services.open} toggle={toggle}>
                <ModalHeader>{services.title}</ModalHeader>
                <ModalBody style={{ maxHeight: "500px", overflowY: "auto" }}>
                    <ul>
                        {services.list.map((obj, index) => {
                            return <li key={index}>{obj}</li>
                        })}
                    </ul>
                </ModalBody>
            </Modal>
            <Snackbar open={snackBar.open} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity={snackBar.severity}>
                    {snackBar.message}
                </Alert>
            </Snackbar>
        </Page>
    )
}
export default LandigPage;