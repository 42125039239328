import React, {useEffect, useRef} from 'react';
import { functions, isEqual, omit } from 'lodash'

const testeMarker = [
    {
        title: 'teste',
        coords: {
            lat: 0,
            lng: 0,
        },
        url: 'https://eneida.io'
    }
]

const GoogleMapsWrapper = ({markers, style,options, onMount, className }) =>{
    const divProps = { ref: useRef(), className }

    useEffect(()=>{    
        
        const onLoad = () =>{
            const map = new window.google.maps.Map(divProps.ref.current, options)
            onMount(map);
            //setMap(map);
        }

        if(!window.google){
            const googleMapScript = document.createElement('script');
            const GOOGLE_MAP_API_KEY ="AIzaSyBPl74_fgrnShZ7um48QxpbIICfTBR8XAc"//window.env.GoogleMapsKey;
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&language=en&libraries=places`;
            const headScript = document.getElementsByTagName('script')[0];
            headScript.parentNode.insertBefore(googleMapScript, headScript);
            googleMapScript.addEventListener('load', onLoad);
            return() => googleMapScript.removeEventListener('load', onLoad);
        }else{
            onLoad();
        }
    },  [divProps.ref, onMount, options, markers]);



    return(
        <>
            <div style={style} {...divProps}/>      
        </>
    );
}

const addMarkers = markers => map => {
    markers.forEach((link, index) => {
      const marker = new window.google.maps.Marker({
        map,
        position: link.coords,
        label: `${index + 1}`,
        title: link.title,
      })
      marker.addListener(`click`, () => {
        //window.location.href = link.url
      })
    })
}

const shouldUpdate = (prevProps, nextProps) => {
    delete prevProps.options.mapTypeId
    const [prevFuncs, nextFuncs] = [functions(prevProps), functions(nextProps)]
    return (
      isEqual(omit(prevProps, prevFuncs), omit(nextProps, nextFuncs)) &&
      prevFuncs.every(fn => prevProps[fn].toString() === nextProps[fn].toString())
    )
  }
  


GoogleMapsWrapper.defaultProps = {
    style:{
        height: '200px',
        margin: '1em 0', 
        borderRadius: '0.5em'
    },
    options: {
      center: { lat: 0, lng: 0 },
      zoom: 15,
    },
    markers: [],
    onMount: addMarkers(testeMarker),
}


export default React.memo(GoogleMapsWrapper, shouldUpdate); 