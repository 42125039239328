import axios from 'axios';

export function postEmail(data){
    return axios({
      method: 'post',
      url: process.env.REACT_APP_API_ENDPOINT + "/v1/message",
      data:  data,
      headers: {'Content-type': 'application/json'}
    })
    .then(response =>{
      if(response.status === 200){
        return response.data;
      }
      else{
        return undefined
      }
    }).catch(error =>{
        console.log(error)
        return undefined;
    });
}