import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch , Router} from "react-router-dom";
import ReactGA from 'react-ga';

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
// pages
import LandingPage from 'pages/LandingPage.js';
import Promos from 'pages/LandingPage/Promos';
import { HelmetProvider } from 'react-helmet-async';
import ComingSoon from './pages/ComingSoon';
import { createBrowserHistory } from 'history';
const  history = createBrowserHistory();

// others

const trackingId = process.env.REACT_APP_GA; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
ReactDOM.render(
  <HelmetProvider>
  <Router history={history}>
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={(props) => <LandingPage {...props} />} />
      <Route exact path="/eshop" render={(props) => <ComingSoon {...props} />} />
      <Route exact path="/promos" render={(props) => <Promos {...props} />} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
  </Router>
  </HelmetProvider>,
  document.getElementById("root")
);

