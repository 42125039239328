import React from 'react';

const ComingSoon = props =>{
        return (
            <video loop="true" autoplay="autoplay" muted>
            <source src={process.env.PUBLIC_URL+'/comingsoon.mp4'} type="video/mp4"/>
            </video>
        )
    
}

export default ComingSoon;