import React, {useEffect} from 'react';
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";

const Promos = () =>{

    useEffect(() => {
        const doc = document.getElementById("zenit_navbar")
        doc.style.backgroundColor ="red"
      });

    return(
        <>
            <ExamplesNavbar />
        </>
    )
}
export default Promos