import React, { useState } from "react";

// reactstrap components
import ReactPlayer from 'react-player';
import { Button, Container, Modal } from "reactstrap";
import imageBackgroud from '../../assets/img/main-page.jpg';
import logo from '../../assets/img/white_logo_transparent_background.png';
// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();
  const [modal, setModal] = useState(false)

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  const toggle = () => setModal(!modal);

  function renderModal(){
    return(
      <Modal  isOpen={modal} toggle={toggle}>
            <ReactPlayer url='https://www.facebook.com/Salaozenite.Coimbra/videos/264224544918302/' 
              playing={true}/>
      </Modal>
    )
  }

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + imageBackgroud + ")",
            alignItems:'flex-end'
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container style={{marginBottom: '50px'}}>
          <div className="motto text-center">
              <img src={logo} alt="Salão zenite" width="50%"/>     
            <br />
            <Button
              className="btn-round mr-1"
              color="neutral"
              onClick={()=>{
                setModal(true);
              }}
              outline
            >
              <i className="fa fa-play" />
              Watch video
            </Button>
          </div>
          {renderModal()}
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
//  <h1 className="presentation-title">Salão Zenite</h1>
//          <img src={logo} alt="Salão zenite" height="150"/>