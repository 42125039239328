import React, { forwardRef } from 'react';
//import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const Page = forwardRef(({
  children,
  title = '',
  ...rest
}, ref) => {
  return (
    <div
      ref={ref}
      {...rest}
    >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content="Salão Zenite, somos um salão de beleza situado em Coimbra." />
          <link rel="canonical" href="https://www.salaozenite.pt/" />
        </Helmet>
        {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;